@import '_variables';
@import '~bootstrap/scss/bootstrap';
@import 'navbar-fixed-left';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
	position: relative;
	min-height: 100%;
}
body {
	margin-bottom: 70px;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: -0.035em;
}

.footer {
	position: absolute;
	bottom: 0;
	// right: 3rem;
	height: 70px;

	.progress {
		margin-left: 5rem;
	}

	&.center {
		transform: translate(-50%, -50%);
		left: 50%;
		padding-top: 2rem;
	}
}

.btn {
	min-width: 135px;
	font-weight: 600;
	padding: 0.5rem 1.5rem;
}

.btn-primary {
	color: $navy-blue;
	&:hover,
	&:active,
	&:focus {
		color: $dark-blue;
		background-color: $sky-blue;
	}
}

.btn-upload {
	min-width: 65px;
	padding: 0.25rem 0.5rem;
	font-weight: 400;
}

.icon-only {
	background-color: transparent;
	border: 0;
	padding: 0 0.5rem;

	&:hover {
		opacity: 0.85;
	}
}

.banking-info {
	.card {
		padding: 0.5rem 0.85rem;
		margin: 0 1.5rem;
		border-width: 2px;

		.card-body {
			min-height: 387px;
		}
	}
}

//tabs
.nav-tabs {
	border-bottom: 0;
	.nav-link {
		color: $nero;
		border: 0;
		margin: 0 1.5rem;
		&.active {
			border-bottom: 3px solid $navy-blue;
			color: $navy-blue;
			-webkit-text-stroke: 0.045rem $navy-blue;
		}
		&:hover {
			color: $navy-blue;
			-webkit-text-stroke: 0.045rem $navy-blue;
		}
	}
	.nav-item {
		display: inline-block;
		&:first-child {
			.nav-link {
				margin-left: 0;
			}
		}
	}
}

.tab-content {
	border-radius: 0.25rem;
	// border: 1px solid $nero;
	padding: 1.3rem 1.75rem;
	// height: 55vh;
	// overflow-y: auto;

	.card-body {
		min-height: 350px;
	}

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: 0 0 0 200px rgba(233, 243, 255, 1) inset;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #fff;
		box-shadow: 0 0 0 200px rgba(51, 76, 110, 1) inset;
		border-radius: 2rem;
	}

	p {
		line-height: 2;
		text-align: justify;
	}
}

// FORMS
.input-group {
	&:not(.has-validation) {
		> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
		> .dropdown-toggle:nth-last-child(n + 3) {
			border-radius: 0.25rem;
		}
	}
	position: relative;
	.icon-only {
		position: absolute;
		right: 0.25rem;
		top: 0.6rem;
		.fa-1x {
			font-size: 1.3em;
		}
	}
}

.form-group {
	margin-bottom: 1rem;
}

.form-label {
	color: $nero;
	font-weight: 500;
}

.form-control,
.form-select {
	border-color: $nero;
	border-width: 2px;

	&:focus {
		border-color: $nero;
		box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25);
	}
}

.form-control.StripeElement {
	padding: 0.55rem 0.75rem;
	&--focus {
		border-color: $nero;
		box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25);
	}

	&--invalid {
		border-color: $danger;
		box-shadow: 0 0 0 0.2rem rgba(193, 84, 54, 0.25);
	}
}

.form-radio-wrap {
	padding: 0.85rem 0.25rem 0.55rem;
	justify-content: space-between;
	border: 2px solid $nero;
	flex-wrap: wrap;
	.form-label {
		align-self: center;
	}
}

.custom-select {
	.placeholder {
		background-color: transparent;
	}
}

input[type='checkbox'] {
	border-color: $nero;
	border-width: 2px;
	transform: scale(1.25);
	margin-right: 0.85rem;

	&.checkbox-custom {
		display: none;
	}

	&:checked {
		background-color: $navy-blue;
		border: $nero;

		+ .checkbox-card {
			background-color: $light-blue;
			border-color: $nero;
			.checkbox-card-wrap {
				color: $nero;
				position: relative;
			}
			button {
				background-color: $navy-blue;
				border: 0;
				color: $white;
				// font-size: 2em;
				width: 30px;
				height: 28px;
				border-radius: 0.25rem;
			}
			.counter-display {
				border: 2px solid $nero;
				border-radius: 0.25rem;
				width: 32px;
				height: 28px;
				text-align: center;
				background-color: $white;
				margin: 0 5px 0;
				font-weight: 500;
			}
		}
	}
	&:focus {
		border-color: $nero;
		box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25);
	}
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
input[type='radio'] {
	&:not(.radio-default) {
		display: none;
	}
	&:checked {
		+ .box {
			background-color: $navy-blue;
			border-color: $navy-blue;
			span {
				color: $white;
			}
		}

		+ .radio-card {
			background-color: $light-blue;
			border-color: $nero;
			.radio-card-wrap {
				color: $nero;
				position: relative;

				&::after {
					content: '\f058';
					position: absolute;
					right: 20px;
					top: 38px;
					font-family: 'FontAwesome';
					font-size: 28px;
					color: $navy-blue;
					font-weight: 900;
				}
				&.location {
					.select-text {
						visibility: hidden;
					}
					&::after {
						top: 23px;
					}
				}
			}
		}
	}
	&.radio-default {
		accent-color: $navy-blue;
		transform: scale(1.5);
		pointer-events: fill;
	}
}
.radio-default-label {
	display: inline-flex;
	max-width: fit-content;
	max-width: -moz-fit-content;
}

.box {
	width: 85px;
	height: 35px;
	background-color: $white;
	transition: all 250ms ease;
	will-change: transition;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	position: relative;
	border: 2px solid $nero;
	border-radius: 0.25rem;
	margin: 0 0.25rem;
	&:active {
		transform: translateY(5px);
	}
	> span {
		position: absolute;
		transform: translate(0, 3px);
		left: 0;
		right: 0;
		transition: all 300ms ease;
		font-size: 1rem;
		user-select: none;
		color: $nero;
		&:before {
			font-size: 1.2em;
			display: block;
			transform: translateY(-80px);
			opacity: 0;
			transition: all 300ms ease-in-out;
			font-weight: normal;
			color: white;
		}
	}
}

.radio-card,
.checkbox-card {
	@extend .box;
	width: 100%;
	height: auto;
	text-align: left;
	overflow: hidden;
	&:active {
		@extend .box:active;
	}
	.radio-card-wrap,
	.checkbox-card-wrap {
		transform: translate(0, 3px);
		transition: all 300ms ease;
		font-size: 1rem;
		user-select: none;
		color: $nero;
		padding: 0.55rem 1.25rem;
		max-height: 115px;
		min-height: 115px;
		&.location {
			 max-height: 130px;
			 min-height: 80px;
			
			@media (min-width: 280px) and (max-width: 320px) {
				max-height: 180px;
				min-height: 150px;
			}
		
			
		}
		&:before {
			font-size: 1.2em;
			display: block;
			transform: translateY(-80px);
			opacity: 0;
			transition: all 300ms ease-in-out;
			font-weight: normal;
			color: white;
		}
		p {
			margin-bottom: 0.5rem;
		}
		.small {
			width: 90%;
		}
		.desc {
			font-size: 0.84em
		}
	}
}

.disabled {
	opacity: 0.65;
	cursor: default;

	&:active,
	&:before {
		transform: none;
		transition: none;
		will-change: unset;
	}
}

.form-group,
.form-radio-wrap,
.select {
	&.error-highlight &__control,
	&.error-highlight .form-control,
	&.error-highlight .form-select,
	&.error-highlight {
		border-color: $danger;
		animation: shake 0.1s ease-in-out 0s 1;

		@keyframes shake {
			0% {
				margin-left: 0rem;
			}
			25% {
				margin-left: 0.5rem;
			}
			75% {
				margin-left: -0.5rem;
			}
			100% {
				margin-left: 0rem;
			}
		}
		&:focus,
		&--is-focused {
			border-color: $danger;
			box-shadow: 0 0 0 0.2rem rgba(193, 84, 54, 0.25);
		}
		&:hover {
			border-color: $danger;
		}
	}
}

.select {
	& .select__control {
		border: 2px solid $nero;
		border-radius: 0.375em;
		&--is-focused {
			border-color: $nero;
			box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25);
		}
		&:hover {
			border-color: $nero;
		}
		&--is-disabled {
			background-color: rgba(221, 221, 221, 0.1);
			cursor: not-allowed;
		}
	}
	&--is-disabled > &__control {
		border-color: rgba(51, 51, 51, 0.25);
	}
}

.PhoneInput {
	position: relative;

	.PhoneInputCountry {
		position: absolute;
		top: 0.75rem;
		left: 1rem;
	}

	.form-control {
		padding-left: 3.8rem;
	}
}

input[type='file'] {
	display: none;
}

.upload-photo {
	border-radius: 50%;
	height: 100px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	.img-preview {
		overflow: hidden;
		height: 100px;
		width: 100px;
		border-radius: 50%;
		pointer-events: none;
		img {
			transform: scale(1);
		}
	}
}

// input[type='date']:after {
// 	content: attr(placeholder);
// }

// FORMS

.content-header {
	a {
		text-decoration: none;
	}
}

.v-middle {
	height: calc(100vh - 70px);
}

.loader-wrapper {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1031;
	background-color: rgba(0, 0, 0, 0.04);
	position: absolute;

	.loader {
		position: relative;
		left: 50%;
		top: 40%;
		width: 75px;
		height: 75px;
		border-width: 7px;
		border-color: rgba(0, 0, 0, 0.05);
		border-top-color: $navy-blue;
		animation: spin 1s infinite linear;
		border-radius: 100%;
		border-style: solid;
	}
	@keyframes spin {
		100% {
			transform: rotate(360deg);
		}
	}
}

.custom-card {
	border: 2px solid $nero;
	padding: 1.25rem 1.5rem;
	border-radius: 0.375rem;
}

.equal-width {
	flex-grow: 1;
	flex-basis: 0;
}

//accordion
.accordion-item {
	border: 2px solid $nero;
}
.accordion-button {
	pointer-events: none;

	&:after,
	> div:last-child {
		pointer-events: all;
	}
	&:not(.collapse) {
		box-shadow: none;
		color: $nero;
		background-color: transparent;
	}
}

//slider
.custom-slider {
	padding: 0.35rem 2rem;

	.slick-arrow {
		border: none;
		outline: none;
		top: 42px;
		&.slick-prev {
			left: 12px;
			top: 42px;

			&:before {
				content: '\f104';
				font-family: FontAwesome;
				color: $navy-blue !important;
				font-size: 30px;
				font-weight: 900;
				opacity: 1;
			}
		}
		&.slick-next {
			right: 12px;

			&:before {
				@extend .slick-prev:before;
				content: '\f105';
			}
		}
		&.slick-disabled {
			&::before {
				opacity: 0.25;
				cursor: auto;
			}
		}
	}
	.slick-slide {
		display: inline-block;
		text-align: center;
		border-radius: 0.375rem;
		padding-top: 10px;
		> div {
			display: inline-block;
			text-align: center;
			background-color: $light-blue;
			width: 65px;
			height: 65px;
			border-radius: 0.375rem;
		}
	}
	.date-card {
		font-weight: bold;
		text-align: center;
		padding-top: 0.25rem;
		cursor: pointer;
		position: relative;
		border-radius: 0.375rem;
		width: 65px;
		height: 65px;

		h3 {
			font-weight: 700;
		}

		&.date-current {
			border: 2px solid $navy-blue;
			color: $navy-blue;
			&:before {
				content: 'TODAY';
				font-size: 0.55rem;
				background-color: $navy-blue;
				color: $white;
				position: absolute;
				padding: 0.05rem 0.2rem;
				left: 13px;
				top: -8px;
				font-weight: 600;
			}
		}
		&.date-disabled {
			cursor: auto;
			pointer-events: none;
			opacity: 0.4;
		}
		&.date-selected {
			background-color: $navy-blue;
			color: $white;
		}
	}
}

.time-available-display {
	margin: 1.5rem 1rem 0;

	h6,
	.small {
		font-weight: 600;
	}

	.time-slot {
		padding-left: 0;
		display: flex;

		@media screen and (max-width: 700px) {
			flex-direction: column;
		}

		li {
			list-style: none;
			display: inline-block;
			padding: 0.5rem 0.75rem;
			cursor: pointer;
			text-align: center;

			div {
				padding: 0.5rem 0.75rem;
				background-color: $light-blue;
				border-radius: 0.375rem;
				font-weight: 600;
				letter-spacing: 0.25px;

				&.time-selected {
					background-color: $navy-blue;
					color: $white;
				}

				&.time-disabled {
					background-color: #e5e5e5;
					color: rgba(51, 51, 51, 0.5);
					cursor: auto;
				}
			}
		}
	}
}

.full-width {
	margin-right: 0;
	margin-left: 0;
	padding-top: 0;
}

.clinic {
	background-color: $clinic !important;
}
.mobile {
	background-color: $mobile !important;
}
.pharmacy {
	background-color: $pharmacy !important;
}

// table

.test-table {
	margin-bottom: 0;

	td {
		padding: 1rem;
	}
}

.modal-backdrop {
	width: 100% !important;
}
