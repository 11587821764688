@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

:root {
  --font-family: 'Montserrat', sans-serif !important;
  --primary-color: #29abe2 !important;
  --secondary-color: #e9f3ff !important;
  --color-dark: #545f71 !important;
  --font-color-light: #ffffff !important;
  --disable-bg-color: #e5e5e5 !important;
  --disable-color: rgba(51, 51, 51, 0.5) !important;
  --hover-primary-color: #33a0cf !important;
}

html,
body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  margin: 0 !important;
  padding: 0 !important;
}

.navbar__custom {
  background-color: var(--secondary-color);
  z-index: 1000 !important;
}

.header__title {
  font-size: 25px !important;
  font-weight: 500;
  margin-bottom: 10px;
}
.header__subtitle {
  font-size: 17px !important;
}

.mapboxgl {
  position: relative;
  font-family: var(--font-family);
  width: 100%;
  height: 550px;
  border-radius: 5px !important;
}
.mapboxgl-popup-content {
  font-size: 15px !important;
  font-weight: 500 !important;
}
.mapboxgl-ctrl-geocoder .suggestions {
  font-family: var(--font-family);
  color: var(--color-dark);
}
.mapboxgl-ctrl-geocoder--input {
  font-family: var(--font-family);
  color: var(--color-dark);
}

.counter {
  display: flex;
  align-items: center;
}
.counter__btn {
  background-color: var(--color-dark);
  border: 0;
  border-radius: 0.25rem;
  color: var(--font-color-light);
  height: 28px;
  width: 30px;
}
.counter__display {
  background-color: var(--font-color-light);
  border: 2px solid var(--color-dark);
  border-radius: 0.25rem;
  font-weight: 500;
  height: 28px;
  margin: 0 5px;
  text-align: center;
  width: 32px;
}

.contact {
  margin-bottom: 0px !important;
}

.form-check-inline {
  margin-right: 0 !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .mapboxgl {
    height: 450px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .filter__service___dropdown {
    width: 100% !important;
  }
}
