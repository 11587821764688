@import 'variables';
@import '~bootstrap/scss/mixins/breakpoints';

body {
	padding-top: 90px;

	@include media-breakpoint-up($navbar-sidebar-breakpoint) {
		padding-top: 0;
	}
}

@mixin navbar-sidebar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: $zindex-fixed;

	@include media-breakpoint-up($navbar-sidebar-breakpoint) {
		bottom: 0;
		width: $navbar-sidebar-width;
		flex-flow: column nowrap;
		align-items: flex-start;

		.navbar-collapse {
			flex-grow: 0;
			flex-direction: column;
			width: 100%;

			.navbar-nav {
				flex-direction: column;
				width: 100%;

				.nav-item {
					width: 100%;

					.dropdown-menu {
						top: 0;
					}
				}
			}
		}
	}
}
